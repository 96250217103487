import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "./components/atoms/loader";
import RequiredAuth from "./components/atoms/requiredAuth";
import Authentication from "./pages/authentication";
import Configuration from "./pages/configuration";
import CustomerProfile from "./pages/customerProfile";
import EnterpriseProfile from "./pages/enterpriseProfile";
import SmeListingPage from "./pages/expertsListing";

const LoginPage = lazy(() => import("./pages/login"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const ExpertProfile = lazy(() => import("./pages/ExpertProfile"));
const SupportPage = lazy(() => import("./pages/support"));
const AivaPage = lazy(() => import("./pages/testAiva"));
const CustomerListingPage = lazy(() => import("./pages/customersListing"));
const SessionExpiredPage = lazy(() => import("./pages/sessionExpired"));

const App = () => {
  return (
    <div className='App'>
      <Routes>
        <Route
          path='*'
          element={
            <Suspense fallback={<Loader />}>
              <Navigate to='/login' replace />
            </Suspense>
          }
        />
        <Route
          path='/sessionExpired'
          element={
            <Suspense fallback={<Loader />}>
              <SessionExpiredPage />
            </Suspense>
          }
        />
        <Route
          path='/authentication'
          element={
            <Suspense fallback={<Loader />}>
              <Authentication />
            </Suspense>
          }
        />
        <Route
          path='/login'
          element={
            <Suspense fallback={<Loader />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route
          path='/experts'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <SmeListingPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/dashboard'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <DashboardPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/experts/:expertId'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <ExpertProfile />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/configurations'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <Configuration />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/support'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <SupportPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/testAiva'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <AivaPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/customers'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <CustomerListingPage />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/customers/:customerId'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <CustomerProfile />
              </Suspense>
            </RequiredAuth>
          }
        />
        <Route
          path='/enterprise/:enterpriseId'
          element={
            <RequiredAuth>
              <Suspense fallback={<Loader />}>
                <EnterpriseProfile />
              </Suspense>
            </RequiredAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
