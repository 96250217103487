import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { customerSuspend } from "../../../services/customerListing";
import {
  fetchEnterpriseMemeberList,
  removeEnterpiseUser,
} from "../../../services/enterprise";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";
import DialogModal from "../../atoms/dialog";
import {
  CaretIcon,
  InboxIcon,
  SuspendIcon,
  TrashIcon,
} from "../../atoms/icons";
import styles from "./enterpriseLicenses.module.scss";

const EnterpriseLicenses = ({ api, setApi }: any) => {
  const [membersList, setMembersList] = useState<any>([]);
  const [membersListCount, setMembersListCount] = useState<any>();
  const [status, setStatus] = useState("ALL");
  const [resultsCount, setResultsCount] = useState<any>(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState<any>("1");
  const [openModal, setOpenModal] = useState(false);
  const [suspendModal, setSuspendModal] = useState(false);

  const [callingApi, setCallingApi] = useState(false);

  const [selectedMember, setSelectedMember] = useState<any>({});

  const [alert, SetAlert] = useState<any>({
    show: false,
    state: "success",
    message: "",
  });

  const { enterpriseId } = useParams();

  const getEnterpriseMemberList = async () => {
    const filterParams = `enterpriseId=${enterpriseId}&limit=${resultsCount}${
      pageNumber !== 1 ? `&skip=${resultsCount * (pageNumber - 1)}` : ""
    }${`&accountApprovalStatus=${status}`}`;
    const res = await fetchEnterpriseMemeberList(filterParams);
    if (res?.data?.status === "success") {
      console.log(res?.data?.data?.licenseCustomerData);
      setMembersList(res?.data?.data?.licenseCustomerData);
      setMembersListCount(res?.data?.data?.totalcustomerCount);
    } else {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    enterpriseId && getEnterpriseMemberList();

    if (membersListCount === 0) setTotalPage(1);
    else setTotalPage(Math.ceil(membersListCount / resultsCount));
  }, [status, pageNumber, resultsCount, membersListCount, callingApi, api]);

  useEffect(() => {
    setPageNumber(1);
  }, [membersListCount, totalPage]);

  const handleOpenModal = (data: any) => {
    setOpenModal(true);
    setSelectedMember(data);
  };

  const handleSuspendModal = (data: any) => {
    setSuspendModal(true);
    setSelectedMember(data);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSuspendModal(false);
    SetAlert({
      show: false,
      state: "",
      message: "",
    });
  };

  const handleRemoveUser = async () => {
    try {
      if (selectedMember?.id) {
        const res = await removeEnterpiseUser(selectedMember?.id);
        if (res?.data?.status === "success") {
          setTimeout(() => {
            setCallingApi(!callingApi);
            handleCloseModal();
            //setOpenModal(false);
          }, 2000);
          SetAlert({
            show: true,
            state: "success",
            message: "User removed successfully",
          });
        } else {
          SetAlert({
            show: true,
            state: "error",
            message: res?.data?.exception?.error?.message,
          });
        }
      }
    } catch (err) {
      SetAlert({
        show: true,
        state: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleSuspendUser = async () => {
    try {
      if (selectedMember?.id) {
        const res = await customerSuspend(selectedMember?.id);
        if (res?.data?.status === "success") {
          setTimeout(() => {
            setCallingApi(!callingApi);
            handleCloseModal();
            //setSuspendModal(false);
          }, 2000);
          SetAlert({
            show: true,
            state: "success",
            message: "User suspended",
          });
        } else {
          SetAlert({
            show: true,
            state: "error",
            message: res?.data?.exception?.error?.message,
          });
        }
      }
    } catch (err) {
      SetAlert({
        show: true,
        state: "error",
        message: "Something went wrong",
      });
    }
  };

  const modalBody = (
    <div style={{ color: "#e0dfe3" }}>
      Are you sure you want to remove this user?
      <div className='d-flex mt-4 gap-2'>
        <Button
          variant='light'
          className='w-50'
          onClick={() => setOpenModal(false)}
        >
          Cancel
        </Button>
        <Button variant='light' className='w-50' onClick={handleRemoveUser}>
          Remove
        </Button>
      </div>
      {/* Alert Message */}
      <CustomAlert
        show={alert.show}
        type={alert.state}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
      />
    </div>
  );

  const suspendModalBody = (
    <div style={{ color: "#e0dfe3" }}>
      Are you sure you want to suspend this user?
      <div className='d-flex mt-4 gap-2'>
        <Button
          variant='light'
          className='w-50'
          onClick={() => setSuspendModal(false)}
        >
          Cancel
        </Button>
        <Button variant='light' className='w-50' onClick={handleSuspendUser}>
          Suspend
        </Button>
      </div>
      {/* Alert Message */}
      <CustomAlert
        show={alert.show}
        type={alert.state}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
      />
    </div>
  );

  const handleStatusChange = (e: any) => {
    setStatus(e?.target?.value);
  };

  const handleResultChange = (e: any) => {
    setResultsCount(e?.target?.value);
  };

  return (
    <>
      <section className={styles.enterpriseListingWrapper}>
        {/* enterpriseListingHeader */}
        <section className={styles.smeListingHeader}>
          <div className={styles.smeListingFilter}>
            <div className={styles.filters}>
              <Button variant='greyNoBorder'>
                <Select
                  labelId='earningSource'
                  id='earningSource'
                  value={status}
                  label='earningSource'
                  IconComponent={CaretIcon}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={"ALL"}>Status</MenuItem>
                  <MenuItem value={"ACTIVE"}>Active</MenuItem>
                  <MenuItem value={"SUSPENDED"}>Suspended</MenuItem>
                </Select>
              </Button>
            </div>

            <div className={styles.pagination}>
              <Button variant='greyNoBorder'>
                Results:
                <Select
                  labelId='resultsCount'
                  id='resultsCount'
                  value={resultsCount}
                  label='resultsCount'
                  IconComponent={CaretIcon}
                  onChange={handleResultChange}
                >
                  <MenuItem value={10}>10 / Page</MenuItem>
                  <MenuItem value={20}>20 / Page</MenuItem>
                  <MenuItem value={30}>30 / Page</MenuItem>
                </Select>{" "}
              </Button>

              <Button variant='greyNoBorder' className={styles.paginationNav}>
                <small
                  className={`${styles.prevPage} ${
                    pageNumber === 1 && styles.disabled
                  }`}
                  onClick={() => {
                    pageNumber > 1 && setPageNumber(pageNumber - 1);
                  }}
                >
                  <CaretIcon />
                </small>
                <span>{pageNumber}</span> / {totalPage}
                <small
                  className={`${styles.nextPage}  ${
                    pageNumber === totalPage && styles.disabled
                  }`}
                  onClick={() => {
                    pageNumber < totalPage && setPageNumber(pageNumber + 1);
                  }}
                >
                  <CaretIcon />
                </small>
              </Button>
            </div>
          </div>
        </section>

        {/* enterpriseListingTable */}

        <section className='tableWrapper'>
          {membersList?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {membersList?.map((data: any) => {
                    return (
                      <TableRow
                        key={data?.id}
                        sx={
                          data?.name === "Invited customer"
                            ? { backgroundColor: "#2b2a31", opacity: "0.8" }
                            : {}
                        }
                      >
                        <TableCell>
                          {" "}
                          <Link to={`/customers/${data.id}`}>
                            {data?.name}{" "}
                          </Link>
                        </TableCell>
                        <TableCell>{data?.email}</TableCell>
                        <TableCell>
                          {data?.name === "Invited customer" ? "-" : data?.role}
                        </TableCell>
                        <TableCell>
                          {data?.name === "Invited customer" && (
                            <span
                              title='Delete'
                              style={{ cursor: "pointer" }}
                              onClick={() => handleOpenModal(data)}
                            >
                              <TrashIcon />
                            </span>
                          )}
                          {data?.name !== "Invited customer" &&
                            data?.accountApprovalStatus !== "SUSPENDED" && (
                              <span
                                title='Suspend'
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSuspendModal(data)}
                              >
                                <SuspendIcon />
                              </span>
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className='noData'>
              <InboxIcon />
              <p>No Member's yet</p>
            </div>
          )}
        </section>
      </section>
      {openModal && (
        <DialogModal
          openModal={openModal}
          setOpenModal={handleCloseModal}
          heading={"Remove user"}
          body={modalBody}
        />
      )}

      {suspendModal && (
        <DialogModal
          openModal={suspendModal}
          setOpenModal={handleCloseModal}
          heading={"Suspend user"}
          body={suspendModalBody}
        />
      )}
    </>
  );
};

export default EnterpriseLicenses;
