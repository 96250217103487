import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  deleteMeetingEvent,
  getCustomerCalendarDetails,
} from "../../../services/calendar";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";
import DialogModal from "../../atoms/dialog";
import {
  CalendarIcon,
  DollarIcon,
  FileIcon,
  ImageIcon,
  LeftIcon,
  MicIcon,
  RightIcon,
  UserIcon,
  VideoIcon,
} from "../../atoms/icons";
import "./calendar.scss";
import styles from "./customerCalendar.module.scss";

interface MeetingProps {
  id: string;
  startTime: string;
  agenda: string;
  amount: any;
  endTime: string;
  meetingLink: string;
  sharedProjects: any[];
  customerUser: {
    avatarUrl: string;
    name: string;
  };
  isDeleted: boolean;
}

interface CustomEvent {
  id: any;
  title: string;
  start: Date | string;
  end: Date | string;
  amount: any;
  meetingLink: string;
  imageUrl: any;
  sharedProjects: any[];
  customerUser: {
    avatarUrl: string;
    name: string;
  };
  name: string;
  publicId: any;
  extendedProps: {
    isDeleted: boolean;
    amount: any;
    meetingLink: any;
    customerUser: {
      meetingLink: string;
      name: string;
    };
    sharedProjects: any[];
    imageUrl: string;
    name: string;
  };
}

interface customEventProps {
  title: string;
  start: any;
  end: any;
  name: string;
  meetingLink: string;
  amount: any;
  sharedProjects: any[];
}

const CustomerCalendar = () => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState<CustomEvent | null>(null);
  const [eventModal, setEventModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | Date>("");
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState<CustomEvent[]>([]);

  const { customerId, enterpriseId } = useParams();
  const id = customerId || enterpriseId;
  const [renderEvents, setRenderEvents] = useState(false);

  const [alert, SetAlert] = useState<any>({
    show: false,
    state: "success",
    message: "",
  });

  // Removing Event Modal
  const [removeEventModal, setRemoveEventModal] = useState(false);

  // success messages
  const [rescheduleEventSuccess, setRescheduleEventSuccess] = useState("");

  // error messages
  const [selectedDateError, setSelectedDateError] = useState("");
  const [selectedTimeError, setSelectedTimeError] = useState("");

  const currentMonth = currentDate.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const fetchMeetingData = useCallback(
    async (customerId: string, start: Date, end: Date) => {
      try {
        const res = await getCustomerCalendarDetails(
          customerId,
          start.toISOString(),
          end.toISOString()
        );
        const meetingsData = res?.data?.data?.data?.meetings;

        setAllEvents(meetingsData);
        const formattedEvents = meetingsData?.map((meeting: any) => {
          return {
            id: meeting.id,
            smeName: meeting.smeUser.name,
            title: meeting.agenda,
            start: meeting.startTime,
            end: meeting.endTime,
            smeImageUrl: meeting.smeUser.avatarUrl,
            amount: meeting?.amount,
            meetingLink: meeting?.meetingLink,
            sharedProjects: meeting?.sharedProjects,
            isDeleted: meeting?.isDeleted,
            documents: {
              aivaPlanFiles: meeting.aivaPlanFiles || [],
              files: meeting.files || [],
            },
          };
        });
        setEvents(formattedEvents);
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  useEffect(() => {
    const startOfMonth = moment(currentDate).startOf("month");
    const endOfMonth = moment(currentDate).endOf("month");

    id && fetchMeetingData(id, startOfMonth.toDate(), endOfMonth.toDate());

    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(currentDate); // Manually set the calendar date
    }
  }, [currentDate, renderEvents]);

  const customHeader = () => {
    return (
      <div className={styles.customHeader}>
        <span onClick={goToPreviousMonth}>
          <LeftIcon />
        </span>
        <span onClick={goToNextMonth}>
          <RightIcon />
        </span>
      </div>
    );
  };

  const goToPreviousMonth = () => {
    const newDate = moment(currentDate).subtract(1, "months").toDate();
    setCurrentDate(newDate);
  };

  const goToNextMonth = () => {
    const newDate = moment(currentDate).add(1, "months").toDate();
    setCurrentDate(newDate);
  };

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentDate(today);
    calendarRef?.current?.getApi()?.gotoDate(today);
  };

  const handleRemoveEvent = async () => {
    try {
      const res = await deleteMeetingEvent(selectedEvent?.id);
      if (res?.data?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "Event Cancelled Successfully",
        });
        setRenderEvents(!renderEvents);
        setTimeout(() => {
          setSelectedEvent(null);
          setRemoveEventModal(false);
          setEventModal(false);
        }, 2000);
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: res?.data?.exception?.error?.message,
        });
      }
    } catch (err) {
      console.log(err);
      SetAlert({
        show: true,
        state: "error",
        message: "Something went wrong.",
      });
    }
  };

  const modalBody = useMemo(() => {
    const extendedInfo: any = selectedEvent?.extendedProps || {};
    const aivaPlanFiles = extendedInfo?.documents?.aivaPlanFiles;
    const docFiles = extendedInfo?.documents?.files;
    return (
      <ul className={styles.callDetails}>
        <li>
          <CalendarIcon />
          <p>{`${moment(selectedEvent?.start).format("D MMMM YYYY")}, ${moment(
            selectedEvent?.start
          ).format("HH:mm")}-${moment(selectedEvent?.end).format("HH:mm")}`}</p>
        </li>
        <li>
          <UserIcon />
          <div className={styles.customerName}>
            <img
              src={extendedInfo?.imageUrl || "/assets/images/userdummy.png"}
              alt=''
              loading='lazy'
            />
            {extendedInfo?.name}
          </div>
        </li>
        <li>
          <DollarIcon /> <p>{extendedInfo?.amount} USD</p>
        </li>
        {!!(aivaPlanFiles?.length || docFiles?.length) && (
          <li className={styles.files}>
            <FileIcon />
            <div className={styles.fileList}>
              {aivaPlanFiles?.map((file: any) => (
                <a href={file?.documentLink} title={file?.name} key={file.id}>
                  <FileIcon />
                  <span>{file?.name}</span>
                </a>
              ))}
              {docFiles?.map((file: any) => (
                <a href={file?.link} title={file?.fileName} key={file.id}>
                  {(file.type.toUpperCase() === "AUDIO" && <MicIcon />) ||
                    (file.type.toUpperCase() === "IMAGE" && <ImageIcon />) ||
                    (file.type.toUpperCase() === "VIDEO" && <VideoIcon />) || (
                      <FileIcon />
                    )}
                  <span>{file?.fileName}</span>
                </a>
              ))}
            </div>
          </li>
        )}
        {!moment(selectedEvent?.end).isBefore(new Date()) && (
          <Button
            variant='dark'
            className='w-100'
            style={{ color: "red" }}
            onClick={() => setRemoveEventModal(true)}
          >
            Cancel
          </Button>
        )}
      </ul>
    );
  }, [selectedEvent]);

  const cancelBody = (
    <div>
      <h5>Event has been Cancelled</h5>
      <Button
        className='w-100 mt-2'
        variant='light'
        onClick={() => setEventModal(false)}
      >
        Close
      </Button>
    </div>
  );

  const handleEventClick = (info: any) => {
    const event = info.event;
    // Extract the date and time from the event
    const eventStart = moment(event.start);
    setSelectedDate(eventStart.toDate());
    setSelectedTime(eventStart.format("HH:mm"));
    setSelectedEvent(event);
    setEventModal(true);
  };

  function renderEventContent(info: any) {
    const event = info?.event;
    const startTime = event.start.toLocaleTimeString([], {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    });
    const unpaidClass = event?.extendedProps?.isDeleted
      ? styles.cancelEvent
      : "";
    return (
      <div
        className={`${styles.customEvent}  ${unpaidClass}
      `}
        title={event?.title}
      >
        <div>
          <span>{moment(event?.start).format("HH:mm")}</span>
          <span>{event?.title}</span>
        </div>
        <img
          src={event?.extendedProps?.imageUrl || "/assets/images/userdummy.png"}
          alt='User Avatar'
          loading='lazy'
        />
      </div>
    );
  }

  return (
    <div className={styles.scheduledCallsFullViewContainer}>
      <div className={styles.scheduledCallsFullViewHeader}>
        <div>
          <Button variant='dark' onClick={handleTodayClick}>
            Today
          </Button>
          <span>{customHeader()}</span>
          <p>{currentMonth}</p>
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        views={{ dayGridMonth: {} }}
        initialView='dayGridMonth' // Set the initial view to month view
        weekends={true}
        showNonCurrentDates={false}
        headerToolbar={{ start: "", center: "", end: "" }}
        dayHeaderFormat={{
          weekday: "long", // Display full weekday names
        }}
        events={events.map((event: any) => ({
          id: event?.id?.toString(), // Convert the id to a string
          title: event?.title,
          start: new Date(event?.start), // Convert start to a Date object
          end: new Date(event?.end), // Convert end to a Date object
          imageUrl: event?.smeImageUrl,
          amount: event?.amount,
          meetingLink: event?.meetingLink,
          sharedProjects: event?.sharedProjects,
          name: event?.smeName,
          isDeleted: event?.isDeleted,
          documents: event.documents,
        }))}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
      />
      {eventModal && selectedEvent && (
        <DialogModal
          openModal={eventModal}
          setOpenModal={setEventModal}
          heading={selectedEvent?.title}
          body={
            selectedEvent?.extendedProps?.isDeleted ? cancelBody : modalBody
          }
        />
      )}
      {/* Cancel Meeting Modal */}
      {removeEventModal && (
        <DialogModal
          openModal={removeEventModal}
          setOpenModal={setRemoveEventModal}
          heading={"Cancel event"}
          body={
            <>
              <p className={styles.headingForRemoveConfirmation}>
                {`Are you sure? ${selectedEvent?.title} will get removed.`}
              </p>
              <div className={styles.buttonForRemoveConfirmation}>
                <Button
                  className='w-50'
                  variant='light'
                  onClick={handleRemoveEvent}
                >
                  Yes
                </Button>
                <Button
                  className='w-50'
                  variant='light'
                  onClick={() => setRemoveEventModal(false)}
                >
                  No
                </Button>
              </div>
              <CustomAlert
                show={alert.show}
                message={alert.message}
                type={alert.state}
                onClose={() => {
                  SetAlert({ ...alert, show: false });
                }}
              />
            </>
          }
        />
      )}
    </div>
  );
};

export default CustomerCalendar;
