import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getSubscriptionPlan } from "../../../services/configurationPlans";
import styles from "./configurationPlans.module.scss";

const ConfigurationPlans = () => {
  const [subscriptionPlanList, setConfigurationList] = useState<any>([]);

  // error

  const fetchSubscriptionPlan = async () => {
    try {
      const res = await getSubscriptionPlan();
      if (res?.data?.status === "success") {
        const productsWithPrices = res?.data?.data?.productsWithPrices;
        // Sort plans by priority
        const sortedPlans = productsWithPrices.sort((a: any, b: any) => {
          const priorityA = a.metadata?.priority || 0; // Default to 0 if priority is undefined
          const priorityB = b.metadata?.priority || 0;
          return priorityA - priorityB;
        });
        setConfigurationList(sortedPlans);
      } else console.log("Something went wrong");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSubscriptionPlan();
  }, []);

  return (
    <div className={styles.plansWrapper}>
      <p>Plans</p>
      <section className='tableWrapper'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple-table'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Name</TableCell>
                <TableCell align='left'>Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionPlanList?.map((plan: any) => (
                <TableRow
                  key={plan?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {plan?.name}
                  </TableCell>
                  <TableCell>
                    {plan?.cost !== null
                      ? `${plan?.price} USD / ${plan?.recurringInterval}`
                      : "NA"}{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </div>
  );
};

export default ConfigurationPlans;
