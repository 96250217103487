import { Alert, AlertColor, Collapse } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

interface AlertProps {
  show?: boolean;
  message?: string;
  type?: AlertColor;
  timeDelay?: number;
  onClose?: (a?: any) => any;
}

const CustomAlert = ({
  show = false,
  type = "info",
  message,
  timeDelay = 3000,
  onClose,
}: AlertProps) => {
  const [collapseIn, setCollapseIn] = useState(show);

  const handleClose = useCallback(() => {
    setCollapseIn(false);
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    setCollapseIn(!!show);
  }, [show]);

  //reset state to hide alert after 3 seconds
  useEffect(() => {
    let time: ReturnType<typeof setTimeout>;
    if (collapseIn) {
      time = setTimeout(() => {
        handleClose();
      }, timeDelay);
    }
    return () => {
      time && clearTimeout(time);
    };
  }, [collapseIn, timeDelay, handleClose]);

  return (
    <Collapse in={collapseIn} unmountOnExit>
      <Alert
        className='mt-3 rounded-pill'
        variant='filled'
        severity={type}
        icon={""}
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Collapse>
  );
};

export default CustomAlert;
