import { Navigate, useLocation } from "react-router-dom";

function RequiredAuth({ children }: { readonly children: JSX.Element }) {
  let location = useLocation();
  const authToken = localStorage.getItem("accessToken");

  if (!authToken) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}

export default RequiredAuth;
