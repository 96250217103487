import { ArrowBack } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatInDateTime } from "../../../helper/time";
import { useEnterpriseProfile } from "../../../pages/enterpriseProfile/enterpriseProfile.context";
import Button from "../../atoms/button";
import DialogModal from "../../atoms/dialog";
import { EditPencil } from "../../atoms/icons";
import {
  AddHours,
  EditAnnualRevenue,
  EditPlan,
  ExistingUserUpgrade,
  InviteTeamMember,
} from "./customerCardModal";

import styles from "./customerInfoCard.module.scss";

type props = {
  badgeList: any[];
  api: any;
  setApi: any;
};

type modalName =
  | ""
  | "addHours"
  | "inviteTeamMember"
  | "editPlan"
  | "upgradeExistingUserPlan"
  | "editAnnualRevenue";

const EnterpriseInfoCard: React.FC<props> = ({ badgeList, api, setApi }) => {
  const navigate = useNavigate();
  const { enterpriseInfo, industrieList = [] } = useEnterpriseProfile();
  const [showModal, setShowModal] = useState<modalName>("");
  const [activeModalInfo, setActiveModalInfo] = useState<any>();
  const industry =
    industrieList.find((ind: any) => ind.id === enterpriseInfo.industryId) ||
    {};
  const joinedDate = formatInDateTime({
    date: enterpriseInfo?.createdAt,
    fullMonth: true,
    timeHours: false,
  });

  const modalClose = () => {
    setShowModal("");
    setActiveModalInfo(null);
  };
  return (
    <>
      <Stack direction='row' alignItems={"center"} spacing={2} mb={2.5}>
        <IconButton
          aria-label='back'
          color={"inherit"}
          onClick={() => {
            navigate("/customers");
          }}
        >
          <ArrowBack sx={{ width: "20px", height: "20px" }} />
        </IconButton>

        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <h5> {enterpriseInfo.companyName}</h5>
            </div>
            <div style={{ display: "inline-flex", gap: "12px" }}>
              <Button
                variant='dark'
                onClick={() => {
                  setShowModal("addHours");
                }}
              >
                Add call hours
              </Button>

              <Button
                variant='dark'
                onClick={() => {
                  setShowModal("inviteTeamMember");
                }}
              >
                Invite team member
              </Button>

              <Button
                className={styles.editBtn}
                variant='light'
                onClick={() => {
                  setShowModal("editPlan");
                }}
              >
                <EditPencil /> Edit plan
              </Button>
            </div>
          </div>
        </div>
      </Stack>

      <div className={styles.userInfoWrapper}>
        <Grid container columnSpacing={5}>
          <Grid item xs>
            <List>
              <ListItem className={`${styles.divider} ${styles.leftCard}`}>
                <Typography className={`${styles.profileLabel}`}>
                  Contact email{" "}
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={enterpriseInfo.email}
                />
              </ListItem>
              <ListItem className={`${styles.divider} ${styles.leftCard}`}>
                <Typography className={`${styles.profileLabel}`}>
                  Industry
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={industry.name || "NA"}
                />
              </ListItem>
              <ListItem className={`${styles.leftCard} ps-0`}>
                <Typography className={`${styles.profileLabel}`}>
                  Plan
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={`Enterprise (${enterpriseInfo.seats} seats)`}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md>
            <List>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}>Joined</Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={joinedDate}
                />
              </ListItem>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}>
                  Hours balance
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={`${enterpriseInfo.callHoursBalance} Hours`}
                />
              </ListItem>
              <ListItem
                secondaryAction={
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    onClick={() => {
                      setShowModal("editAnnualRevenue");
                    }}
                  >
                    <EditPencil />
                  </IconButton>
                }
              >
                <Typography className={styles.profileLabel}>
                  Total annual revenue
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={`${enterpriseInfo?.totalRevenue || 0} USD`}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>

      {showModal === "addHours" && (
        <DialogModal
          openModal={!!showModal}
          setOpenModal={(a: boolean) => modalClose()}
          heading={"Add call hours"}
          body={<AddHours onClose={() => modalClose()} />}
        />
      )}

      {showModal === "inviteTeamMember" && (
        <DialogModal
          openModal={!!showModal}
          setOpenModal={(a: boolean) => modalClose()}
          heading={"Invite team member"}
          body={
            <InviteTeamMember
              api={api}
              setApi={setApi}
              onClose={(userEmail: any) => {
                if (userEmail) {
                  setActiveModalInfo(userEmail);
                  setShowModal("upgradeExistingUserPlan");
                } else {
                  modalClose();
                }
              }}
            />
          }
        />
      )}

      {showModal === "upgradeExistingUserPlan" && activeModalInfo && (
        <DialogModal
          openModal={!!showModal}
          setOpenModal={() => modalClose()}
          heading={"Upgrade existing user plan"}
          body={
            <ExistingUserUpgrade
              newEmail={activeModalInfo}
              onClose={() => modalClose()}
            />
          }
        />
      )}

      {showModal === "editPlan" && (
        <DialogModal
          openModal={!!showModal}
          setOpenModal={() => modalClose()}
          heading={"Edit plan"}
          body={<EditPlan onClose={() => modalClose()} />}
        />
      )}

      {showModal === "editAnnualRevenue" && (
        <DialogModal
          openModal={!!showModal}
          setOpenModal={() => modalClose()}
          heading={"Edit Annual Revenue"}
          body={<EditAnnualRevenue onClose={() => modalClose()} />}
        />
      )}
    </>
  );
};
export default EnterpriseInfoCard;
