import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BadgeTypes } from "../../../../types/commontypes";
import {
  addBadge,
  fetchBadges,
  updateBadge,
} from "../../../services/configurations";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";
import CustomStatus from "../../atoms/customStatus";
import CustomToggle from "../../atoms/customToggle";
import DialogModal from "../../atoms/dialog";
import { EditPencil, PlusIcon } from "../../atoms/icons";
import styles from "./manualBadges.module.scss";

const toggleValues = [
  {
    id: "Active",
    name: "Active",
  },
  {
    id: "Inactive",
    name: "Inactive",
  },
];

const ManualBadges = () => {
  const [badgesListing, setBadgesListing] = useState<BadgeTypes[]>([]);
  const [callBadgeListingApi, setCallBadgeListingApi] = useState(false);
  const [openAddBadgeModal, setOpenAddBadgeModal] = useState(false);
  const [badgeName, setBadgeName] = useState("");
  const [status, setStatus] = useState("Active");
  const [openEditBadgeModal, setOpenEditBadgeModal] = useState(false);
  const [editBadgeId, setEditBadegeId] = useState("");
  const [editbadgeName, setEditBadegeName] = useState("");
  const [editbadgeStatus, setEditBadgeStatus] = useState("");
  const [emptyBadegeNameStatus, setEmptyBadgeNameStatus] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionEmpty, setDescriptionEmpty] = useState(false);
  const [editDescription, setEditDescription] = useState("");
  const [disableEditButton, setDisableEditButton] = useState(false);

  const [alert, SetAlert] = useState<any>({
    show: false,
    state: "success",
    message: "",
  });

  useEffect(() => {
    fetchBadges()
      ?.then((res) => {
        if (res?.data?.status === "success") {
          setBadgesListing(res?.data?.data?.badges);
        } else {
          console.log(res?.data?.exception?.error?.message);
        }
      })
      .catch((err) => {
        console.log("Something went wrong");
      });
  }, [callBadgeListingApi]);

  const handleAddBadgeModal = () => {
    setOpenAddBadgeModal(true);
  };

  const handleAddBadgeCloseModal = () => {
    setEmptyBadgeNameStatus(false);
    setBadgeName("");
    setOpenAddBadgeModal(false);
    setDescription("");
    setDescriptionEmpty(false);
    SetAlert({
      show: false,
      state: "",
      message: "",
    });
  };

  const handleEditBadgeCloseModal = () => {
    setEmptyBadgeNameStatus(false);
    setOpenEditBadgeModal(false);
    setEditDescription("");
    setDescriptionEmpty(false);
    SetAlert({
      show: false,
      state: "",
      message: "",
    });
  };

  const handleBadgeName = (e: any) => {
    setBadgeName(e?.target?.value);
  };

  const handleAddBadge = async () => {
    if (badgeName?.trim() === "") {
      setEmptyBadgeNameStatus(true);
      return;
    }
    if (description?.trim() === "") {
      setEmptyBadgeNameStatus(false);
      setDescriptionEmpty(true);
      return;
    }

    setEmptyBadgeNameStatus(false);
    setDescriptionEmpty(false);
    const data = {
      name: badgeName,
      description: description,
      status: status === "Active" ? true : false,
    };
    const res = await addBadge(data);
    if (res?.data?.status === "success") {
      SetAlert({
        show: true,
        state: "success",
        message: "Badge added successfully.",
      });
      setTimeout(() => {
        handleAddBadgeCloseModal();
        setCallBadgeListingApi(!callBadgeListingApi);
      }, 2000);
    } else {
      SetAlert({
        show: true,
        state: "error",
        message: res?.data?.exception?.error?.message,
      });
    }
  };

  const handleEditBadge = (data: BadgeTypes) => {
    setEditBadegeId(data?.id);
    setEditBadegeName(data?.name);
    setEditDescription(data?.description);
    setEditBadgeStatus(data?.status ? "Active" : "Inactive");
    setOpenEditBadgeModal(true);
    //setDisableEditButton(true);
  };

  const handleEditBadgeName = (event: any) => {
    setEditBadegeName(event?.target?.value);
    //setDisableEditButton(false);
  };

  const handleEditDescription = (event: any) => {
    setEditDescription(event?.target?.value);
    //setDisableEditButton(false);
  };

  const handleEditBadgeAction = async () => {
    if (editbadgeName?.trim() === "") {
      setEmptyBadgeNameStatus(true);
      return;
    }
    if (editDescription?.trim() === "") {
      setEmptyBadgeNameStatus(false);
      setDescriptionEmpty(true);
      return;
    }
    setEmptyBadgeNameStatus(false);
    setDescriptionEmpty(false);
    const data = {
      name: editbadgeName,
      description: editDescription,
      status: editbadgeStatus === "Active" ? true : false,
    };
    if (editBadgeId) {
      const res = await updateBadge(editBadgeId, data);
      if (res?.data?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "Badge updated successfully.",
        });
        setTimeout(() => {
          handleEditBadgeCloseModal();
          setCallBadgeListingApi(!callBadgeListingApi);
        }, 2000);
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: res?.data?.exception?.error?.message,
        });
      }
    }
  };

  const addBadgeModalBody = (
    <div className={styles.badgeModalBody}>
      <CustomToggle type={status} setType={setStatus} values={toggleValues} />
      <div className='w-100'>
        <label style={{ color: "#adaab5", fontSize: "14px" }}>Name</label>
        <input
          className='input-field'
          type='text'
          placeholder='Name this badge'
          value={badgeName}
          onChange={handleBadgeName}
        />
      </div>
      {emptyBadegeNameStatus && (
        <span className='invalid-feedback d-block'>This is required</span>
      )}
      <div>
        <label className={styles.label}>Description</label>
        <textarea
          className={styles.textBoxTextArea}
          placeholder='Add description...'
          rows={4}
          cols={50}
          value={description}
          onChange={(e: any) => setDescription(e?.target?.value)}
        />
        {descriptionEmpty && (
          <span className='invalid-feedback d-block'>This is required</span>
        )}
      </div>
      <Button variant='light' className='w-100 mt-1' onClick={handleAddBadge}>
        Add
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.state}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
      />
    </div>
  );

  const editBadgeModalBody = (
    <div className={styles.badgeModalBody}>
      <CustomToggle
        type={editbadgeStatus}
        setType={setEditBadgeStatus}
        values={toggleValues}
      />
      <div className='w-100'>
        <label style={{ color: "#adaab5", fontSize: "14px" }}>Name</label>
        <input
          className='input-field'
          type='text'
          placeholder='Name this badge'
          value={editbadgeName}
          onChange={handleEditBadgeName}
        />
      </div>
      {emptyBadegeNameStatus && (
        <span className='invalid-feedback d-block'>This is required</span>
      )}
      <div>
        <label className={styles.label}>Description</label>
        <textarea
          className={styles.textBoxTextArea}
          placeholder='Add description...'
          rows={4}
          cols={50}
          value={editDescription}
          onChange={handleEditDescription}
        />
        {descriptionEmpty && (
          <span className='invalid-feedback d-block'>This is required</span>
        )}
      </div>
      <Button
        variant='light'
        //variant={disableEditButton ? "darkDisabled" : "light"}
        className='w-100 mt-1'
        onClick={handleEditBadgeAction}
      >
        Save
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.state}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
      />
    </div>
  );

  return (
    <div className={styles.plansWrapper}>
      <div>
        <p>Manual badges</p>
        <Button variant='dark' onClick={handleAddBadgeModal}>
          <PlusIcon /> Add badge
        </Button>
      </div>
      <section className='tableWrapper'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple-table'>
            <TableHead>
              <TableRow>
                <TableCell align='left' sx={{ width: "45%" }}>
                  Name
                </TableCell>
                <TableCell align='left' sx={{ width: "45%" }}>
                  Status
                </TableCell>
                <TableCell align='left' sx={{ width: "10%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {badgesListing?.map(
                (badge) =>
                  !badge?.isAutomated && (
                    <TableRow
                      key={badge?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component='th'
                        scope='row'
                        title={badge?.description}
                      >
                        {badge.name}
                      </TableCell>
                      <TableCell>
                        {badge?.status ? (
                          <CustomStatus status='approved' message={"Active"} />
                        ) : (
                          <CustomStatus status='pending' message={"Inactive"} />
                        )}
                      </TableCell>
                      <TableCell>
                        <span
                          onClick={() => handleEditBadge(badge)}
                          style={{ cursor: "pointer" }}
                        >
                          <EditPencil />
                        </span>
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      {openAddBadgeModal && (
        <DialogModal
          openModal={openAddBadgeModal}
          setOpenModal={handleAddBadgeCloseModal}
          heading='Add badge'
          body={addBadgeModalBody}
        />
      )}
      {openEditBadgeModal && (
        <DialogModal
          openModal={openEditBadgeModal}
          setOpenModal={handleEditBadgeCloseModal}
          heading='Edit badge'
          body={editBadgeModalBody}
        />
      )}
    </div>
  );
};

export default ManualBadges;
