import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import CustomStatus from "../../atoms/customStatus";

interface ListingProps {
  id: string;
  name: string;
  email: string;
  industry: string;
  status: boolean;
  createdAt: string;
  lastActivity: string;
  totalEarnings: number | null;
}

const SmeListingTable = ({ listing }: { listing: ListingProps[] }) => {
  // Function to calculate the activity duration
  function getActivityDuration(lastActivity: string) {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    const today = new Date();
    const activityDate = new Date(lastActivity); // Explicitly cast to Date
    const timeDiff = today.getTime() - activityDate.getTime();
    const daysDiff = Math.round(timeDiff / oneDay);

    if (daysDiff <= 3) {
      return `${daysDiff}d`;
    } else if (daysDiff <= 7) {
      return "1W";
    } else if (daysDiff <= 30) {
      return "1M";
    } else {
      // You can add more cases for longer inactivity if needed
      return "1M+";
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "15%" }}>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Industry</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Joined</TableCell>
            <TableCell>Last Activity</TableCell>
            <TableCell>Total earnings(USD)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listing?.map((row: any) => (
            <TableRow
              key={row?.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell>
                <Link to={`/experts/${row.id}`}>{row?.name} </Link>
              </TableCell>

              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.industry}</TableCell>
              <TableCell>
                {row?.status === "APPROVED" && (
                  <CustomStatus status='approved' message={row?.status} />
                )}
                {row?.status === "WAITING_FOR_APPROVAL" && (
                  <CustomStatus status='pending' message={"PENDING"} />
                )}
                {(row?.status === "REJECTED" ||
                  row?.status === "SUSPENDED") && (
                  <CustomStatus status='error' message={row?.status} />
                )}
              </TableCell>
              <TableCell>
                {new Date(row?.createdAt).toLocaleString("en-IN", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })}
              </TableCell>
              <TableCell>
                {row?.lastActivity
                  ? new Date(row?.lastActivity).toLocaleString("en-IN", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })
                  : "NA"}
              </TableCell>
              <TableCell align='right'>
                {row?.totalEarnings ? row?.totalEarnings : "NA"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SmeListingTable;
