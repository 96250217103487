import { HTTP_METHODS } from "../common/constants";
import { toQueryString } from "../helper/utility";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}admin`;

export const getCustomerListing = (query?: string) => {
  let url;
  query ? (url = `${baseUrl}/customer${query}`) : (url = `${baseUrl}/customer`);
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const addCustomer = (data: any) => {
  let url = `${baseUrl}/customer/enterprise`;
  return makeNetworkCall(HTTP_METHODS.POST, url, data);
};

export const fetchCustomerProfile = (id: string) => {
  let url = `${baseUrl}/customer/profile/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const customerSuspend = (id: string) => {
  let url = `${baseUrl}/customer/profile/${id}/suspend`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
};

type ActivitylogProps = {
  userId: string;
  skip?: number;
  limit?: number;
};
export const fetchCustomerActivityLog = (payload: ActivitylogProps) => {
  const date = new Date();
  let params = { skip: 0, limit: 10, ...payload, endTime: date.toISOString() };
  let paramString = toQueryString(params);
  let url = `${baseUrl}/customer/activity/log?${paramString}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const fetchCustomerInteraction = (payload?: any) => {
  let url = `${baseUrl}/customer/interactions`;
  const query = toQueryString(payload);
  if (query) {
    url += "?" + query;
  }
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const fetchCustomerTransactions = (query: string) => {
  let url;
  query
    ? (url = `${baseUrl}/customer/transactions?${query}`)
    : (url = `${baseUrl}/customer/transactions`);
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const revokeSupendedCustomer = (id: string) => {
  let url = `${baseUrl}/customer/profile/${id}/active`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
};
