import { useEffect, useState } from "react";
import TabPanel from "../../components/atoms/tabPanel";
import CustomerInfoCard from "../../components/molecules/customerInfoCard";
import CustomerInteractions from "../../components/organisms/CustomerInteractions";
import ActivityLog from "../../components/organisms/activityLog";
import CustomerCalendar from "../../components/organisms/customerCalendar";
import CustomerTransactions from "../../components/organisms/customerTransactions";
import Header from "../../components/organisms/header";
import CustomerProfileProvider, {
  useCustomerProfile,
} from "./customerProfile.context";

type props = {};

const CustomerProfile: React.FC<props> = () => {
  return (
    <div className='mainWrapper'>
      <Header />
      <CustomerProfileProvider>
        <CustomerContent />
      </CustomerProfileProvider>
    </div>
  );
};
export default CustomerProfile;

const CustomerContent = () => {
  const [badgeList, setBadgeList] = useState([]);
  const { customerInfo } = useCustomerProfile();
  return (
    <>
      <div className='contentWrapper'>
        <CustomerInfoCard badgeList={badgeList} />
        <TabPanel
          panels={
            customerInfo?.role !== "CUSTOMER_ENTERPRISE"
              ? [
                  {
                    tabLabel: "Interactions",
                    tabContent: <CustomerInteractions />,
                  },
                  {
                    tabLabel: "Calendar",
                    tabContent: <CustomerCalendar />,
                  },
                  {
                    tabLabel: "Transactions",
                    tabContent: <CustomerTransactions />,
                  },

                  {
                    tabLabel: "Activity log",
                    tabContent: <ActivityLog infoType='CUSTOMER' />,
                  },
                ]
              : [
                  {
                    tabLabel: "Interactions",
                    tabContent: <CustomerInteractions />,
                  },
                  {
                    tabLabel: "Calendar",
                    tabContent: <CustomerCalendar />,
                  },
                  {
                    tabLabel: "Activity log",
                    tabContent: <ActivityLog infoType='CUSTOMER' />,
                  },
                ]
          }
        />
      </div>
    </>
  );
};
