import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";
const BaseAdminUrl = `${process.env.REACT_APP_API_BASE_URL}admin`;

export const getAivaPayout = () => {
  let url = `${BaseAdminUrl}/aiva/payout`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

type AmountProps = {
  amount: number;
  payoutId: string;
};
export const updateAivaPayoutAmount = (payload: AmountProps) => {
  const { payoutId, ...body } = payload;
  let url = `${BaseAdminUrl}/aiva/payout/${payoutId}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};

export const fetchBadges = () => {
  let url = `${BaseAdminUrl}/badge`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const addBadge = (body: any) => {
  let url = `${BaseAdminUrl}/badge`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const updateBadge = (id: string, body: any) => {
  let url = `${BaseAdminUrl}/badge/${id}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};

export const fetchGlobalConfig = () => {
  let url = `${BaseAdminUrl}/global/config`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const updateGlobalConfig = (id: string, body: any) => {
  let url = `${BaseAdminUrl}/global/config/${id}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};
