import { useEffect, useState } from "react";
import TabPanel from "../../components/atoms/tabPanel";
import EnterpriseInfoCard from "../../components/molecules/customerInfoCard/enterpriseInfoCard";
import EnterpriseLicenses from "../../components/organisms/enterpriseLicenses";
import EnterprisePrograms from "../../components/organisms/enterprisePrograms";
import Header from "../../components/organisms/header";
import EnterpriseProfileProvider from "./enterpriseProfile.context";

type props = {};

const EnterpriseProfile: React.FC<props> = () => {
  const [badgeList, setBadgeList] = useState([]);
  const [callingLicenseApi, setCallingLicenseApi] = useState(false);

  return (
    <div className='mainWrapper'>
      <Header />
      <EnterpriseProfileProvider>
        <div className='contentWrapper'>
          <EnterpriseInfoCard
            badgeList={badgeList}
            api={callingLicenseApi}
            setApi={setCallingLicenseApi}
          />
          <TabPanel
            panels={[
              {
                tabLabel: "Licenses",
                tabContent: (
                  <EnterpriseLicenses
                    api={callingLicenseApi}
                    setApi={setCallingLicenseApi}
                  />
                ),
              },
              {
                tabLabel: "Programs",
                tabContent: <EnterprisePrograms />,
              },
            ]}
          />
        </div>
      </EnterpriseProfileProvider>
    </div>
  );
};
export default EnterpriseProfile;
