import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { getIndustries } from "../../services/authForms";
import {
  fetchEnterpriseDetail,
  inviteMember,
  makeUserToEnterpiseUser,
  updateEnterpriseDetail,
  UpdateEnterpriseProps,
} from "../../services/enterprise";
import {
  EnterpriseProfileProviderProps,
  EnterpriseProfileState,
  customerUserProps,
} from "./type";

const sampleData = {
  id: "",
  email: "",
  createdAt: "",
  seats: 0,
  callHours: 0,
  companyName: "",
  location: "",
};

const EnterpriseProfileContext = createContext<
  EnterpriseProfileState | undefined
>(undefined);

// hook for getting context state and function
export const useEnterpriseProfile = (): EnterpriseProfileState => {
  const context = useContext(EnterpriseProfileContext);
  if (!context) {
    throw new Error(
      "EnterpriseProfile must be used within a EnterpriseProfileProvider"
    );
  }
  return context;
};

// Context Provide
const EnterpriseProfileProvider = ({
  children,
}: EnterpriseProfileProviderProps) => {
  const { enterpriseId = "" } = useParams();
  const [enterpriseInfo, setEnterpriseInfo] =
    useState<customerUserProps>(sampleData);
  const [industrieList, setIndustrieList] = useState<any[]>([]);

  const refreshProfile = useCallback(() => {
    if (!!enterpriseId) {
      fetchEnterpriseDetail(enterpriseId)?.then((res: any) => {
        const resData = res?.data || {};
        if (resData?.status === "success") {
          setEnterpriseInfo(resData.data.enterpriseProfile);
        }
      });
    }
  }, [enterpriseId]);

  const updateEnterprise = (
    paylaod: UpdateEnterpriseProps,
    onResponse?: (res: any) => void
  ) => {
    const apiBody = {
      companyName: enterpriseInfo.companyName,
      location: enterpriseInfo.location,
      seats: enterpriseInfo.seats,
      callHours: enterpriseInfo.callHours,
      ...paylaod,
    };
    updateEnterpriseDetail(enterpriseId, apiBody)?.then((res: any) => {
      const resData = res?.data || {};
      if (resData?.status === "success") {
        const resInfo = resData.data?.enterpriseProfileData || {};
        setEnterpriseInfo((currentState: any) => ({
          ...currentState,
          ...resInfo,
        }));
      }
      onResponse && onResponse(resData);
    });
  };

  const inviteNewMember = useCallback(
    (email: string, onResponse?: (r: any) => void) => {
      const payload = {
        email: email,
        companyName: enterpriseInfo.companyName,
        location: enterpriseInfo?.location,
        enterpriseProfileId: enterpriseInfo.id,
      };
      inviteMember(payload)?.then((res: any) => {
        const resData = { ...res?.data, statusCode: res.status };
        onResponse && onResponse(resData);
      });
    },
    [enterpriseInfo.companyName, enterpriseInfo.id, enterpriseInfo?.location]
  );

  const upgradeToEnterpisePlan = useCallback(
    (email: string, onResponse?: (r: any) => void) => {
      makeUserToEnterpiseUser({
        email: email,
        enterpriseProfileId: `${enterpriseInfo?.id}`,
      }).then((res: any) => {
        const resData = res?.data || {};
        refreshProfile();
        onResponse && onResponse(resData);
      });
    },
    [enterpriseInfo?.id]
  );

  useEffect(() => {
    refreshProfile();
  }, [refreshProfile]);

  useEffect(() => {
    getIndustries()?.then((res) => {
      const resData = res?.data;
      if (resData?.status) {
        setIndustrieList(resData.data);
      }
    });
  }, []);

  useEffect(() => {
    console.log("enterpriseInfo", enterpriseInfo);
  }, [enterpriseInfo]);

  return (
    <EnterpriseProfileContext.Provider
      value={{
        enterpriseInfo,
        industrieList,
        refreshProfile,
        updateEnterprise,
        inviteNewMember,
        upgradeToEnterpisePlan,
      }}
    >
      {children}
    </EnterpriseProfileContext.Provider>
  );
};

export default EnterpriseProfileProvider;
