import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProjectList } from "../../../services/enterprise";
import Button from "../../atoms/button";
import CustomStatus from "../../atoms/customStatus";
import { CaretIcon, InboxIcon } from "../../atoms/icons";
import styles from "./enterprisePrograms.module.scss";

const EnterprisePrograms = () => {
  const [programList, setProgramList] = useState<any>([]);
  const [programListCount, setProgramListCount] = useState<any>([]);
  const [resultsCount, setResultsCount] = useState<any>(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState<any>("1");

  const { enterpriseId } = useParams();

  const getProgramList = async () => {
    const filterParams = `enterpriseProfileId=${enterpriseId}&limit=${resultsCount}${
      pageNumber !== 1 ? `&skip=${resultsCount * (pageNumber - 1)}` : ""
    }`;
    const res = await fetchProjectList(filterParams);
    if (res?.data?.status === "success") {
      setProgramList(res?.data?.data?.projectList);
      setProgramListCount(res?.data?.data?.totalProjectCount);
    } else {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    enterpriseId && getProgramList();

    if (programListCount !== 0)
      setTotalPage(Math.ceil(programListCount / resultsCount));
  }, [pageNumber, resultsCount, programListCount, resultsCount]);

  useEffect(() => {
    setPageNumber(1);
  }, [programListCount, totalPage]);

  // Function to format date in the desired format using moment
  const formatDateTime = (dateTimeString: any) => {
    return moment(dateTimeString).format("MM/DD/YYYY HH:mm");
  };

  const handleResultChange = (e: any) => {
    setResultsCount(e?.target?.value);
  };

  return (
    <>
      <section className={styles.enterpriseListingWrapper}>
        {/* enterpriseListingHeader */}
        <section className={styles.smeListingHeader}>
          <div className={styles.smeListingFilter}>
            <div className={styles.pagination}>
              <Button variant='greyNoBorder'>
                Results:
                <Select
                  labelId='resultsCount'
                  id='resultsCount'
                  value={resultsCount}
                  label='resultsCount'
                  IconComponent={CaretIcon}
                  onChange={handleResultChange}
                >
                  <MenuItem value={10}>10 / Page</MenuItem>
                  <MenuItem value={20}>20 / Page</MenuItem>
                  <MenuItem value={30}>30 / Page</MenuItem>
                </Select>{" "}
              </Button>

              <Button variant='greyNoBorder' className={styles.paginationNav}>
                <small
                  className={`${styles.prevPage} ${
                    pageNumber === 1 && styles.disabled
                  }`}
                  onClick={() => {
                    pageNumber > 1 && setPageNumber(pageNumber - 1);
                  }}
                >
                  <CaretIcon />
                </small>
                <span>{pageNumber}</span> / {totalPage || 1}
                <small
                  className={`${styles.nextPage}  ${
                    pageNumber === totalPage && styles.disabled
                  }`}
                  onClick={() => {
                    pageNumber < totalPage && setPageNumber(pageNumber + 1);
                  }}
                >
                  <CaretIcon />
                </small>
              </Button>
            </div>
          </div>
        </section>

        {/* enterpriseListingTable */}
        {programList?.length > 0 ? (
          <section className='tableWrapper'>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "33%" }}>Name</TableCell>
                    <TableCell sx={{ width: "33%" }}>Status</TableCell>
                    <TableCell sx={{ width: "33%" }}>Created on</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {programList?.map((data: any) => (
                    <TableRow key={data?.id}>
                      <TableCell>{data?.projectName}</TableCell>
                      <TableCell>
                        {data?.projectStatus === "FINISHED" && (
                          <CustomStatus
                            status='approved'
                            message={data?.projectStatus?.toUpperCase()}
                          />
                        )}
                        {data?.projectStatus === "TO_BE_STARTED" && (
                          <CustomStatus
                            status='pending'
                            message={data?.projectStatus?.toUpperCase()}
                          />
                        )}
                        {data?.projectStatus === "IN_SHELL" && (
                          <CustomStatus
                            status='pending'
                            message={data?.projectStatus?.toUpperCase()}
                          />
                        )}
                        {data?.projectStatus === "IN_PROGRESS" && (
                          <CustomStatus
                            status='in-progress'
                            message={data?.projectStatus?.toUpperCase()}
                          />
                        )}
                        {data?.projectStatus === "DELETED" && (
                          <CustomStatus
                            status='error'
                            message={data?.projectStatus?.toUpperCase()}
                          />
                        )}
                      </TableCell>
                      <TableCell>{formatDateTime(data?.createdAt)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </section>
        ) : (
          <div className='noData'>
            <InboxIcon />
            <p>No Program's yet</p>
          </div>
        )}
      </section>
    </>
  );
};

export default EnterprisePrograms;
